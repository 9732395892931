<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
  body {
    height: 100%;
    background: linear-gradient(#29345A, #151821);
    background-repeat : no-repeat;
    background-size : cover;
  }
</style>
